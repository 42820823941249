import React from "react";
import "./StudentStakeholderPage.css";
import {StepCard} from "../molecules/StepCard";

export function StudentStakeholderPage() {
  const steps = [
    {
      img: "/studentstakeholder/1.svg",
      text: "Here is a manual of how you can be a part of the BlendED programme and how the semester will look like. ",
    },
    {
      img: "/studentstakeholder/2.svg",
      text: "First you need to apply for BlendED through the website. \n If you are accepted for the BlendED program, you will go to the kick-off week in february.",
    },
    {
      img: "/studentstakeholder/3.svg",
      text: "Here starts your kick-off week. Here you get all the information in depth about all the programs and companies. ",
    },
    {
      img: "/studentstakeholder/4.svg",
      text: "Get to know your team and brainstorm to make the program work. \n Also here you will plan for the next few months online will go.",
    },
    {
      img: "/studentstakeholder/5.svg",
      text: "Here is your first big presentation! \n Here you will explain what you will do and how you will manage it.",
    },
    {
      img: "/studentstakeholder/6.svg",
      text: "Get to know your team and brainstorm to make the program work.\nAlso here you will plan for the next few months online will go.",
    },
    {
      img: "/studentstakeholder/7.svg",
      text: "Here is your first big presentation!\nHere you will explain what you will do and how you will manage it.",
    },
    {
      img: "/studentstakeholder/8.svg",
      text: "Time to go online!\nFrom here, you are back in your homecountry and will work as a team on the project from home.",
    },
    {
      img: "/studentstakeholder/9.svg",
      text: "Now you are almost at the end of your BlendED programme.\nYou will fly to another country to have you last week togheter as a team.",
    },
    {
      img: "/studentstakeholder/10.svg",
      text: "Now are the last steps of your BlendED program.You will need to make the last changes and prepare for the big presentation.",
    },
    {
      img: "/studentstakeholder/11.svg",
      text: "Big presentation time!\nIt is time to present you work to the client. ",
    },
    {
      img: "/studentstakeholder/12.svg",
      text: "You are finished, show off your work and enjoy your last days with your team!",
    },
  ];
  return (
    <div className="pagecontainer">
      <section title="welcome">
        <div>
          <h1>Student</h1>
          <p>A manual to be a BlendED student</p>
        </div>
        <img
          src="/studentstakeholder.svg"
          alt="student stakeholder illustration"
        />
      </section>
      <section title="banner">
        <img src="/studentbanner.svg" alt="student banner illustration" />
      </section>
      <section title="tutorial">
        <ul className="stepslist">
          {steps.map((step, index) => {
            return (
              <StepCard key={index} step={step} title={`Step ${index + 1}`} />
            );
          })}
        </ul>
      </section>
    </div>
  );
}
