import React from "react";
import "./HomePage.css";
import gsap from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {RoleCard} from "../molecules/RoleCard";
import {useNavigate} from "react-router-dom";

export function HomePage() {
  const navigate = useNavigate();
  gsap.registerPlugin(ScrollToPlugin);
  const scroll = (id) => {
    gsap.to(window, {duration: 0.4, scrollTo: id});
  };
  return (
    <div className="App">
      <section title="welcome" id="welcome">
        <img className="banner" src="/worldbanner.svg" alt="big illustration" />
      </section>
      <section title="middle">
        <h2>BlendED</h2>
        <div className="quote">
          <span>
            <p>
              BlendED is an educational concept that combines physical academic
              mobility, virtual mobility and blended learning. It aims to
              promote employability of higher education students.{" "}
            </p>{" "}
            <p>
              It's supported by partnership(s), cross-border or not, sharing
              commons objectives and performing a joint work plan. With
              partnership here we mean a consortium of at least one university
              and one non academic partner.
            </p>
          </span>
          <img src="/quote.svg" alt="world illustration" />
        </div>
      </section>
      <section title="stakeholders" id="stakeholders">
        <ul className="rolelist">
          <li>
            <RoleCard
              role="Company"
              onClick={() => navigate("/stakeholders/company")}
            />
          </li>
          <li>
            <RoleCard
              role="Student"
              onClick={() => navigate("/stakeholders/student")}
            />
          </li>
          <li>
            <RoleCard
              role="University"
              onClick={() => navigate("/stakeholders/university")}
            />
          </li>
        </ul>
      </section>
    </div>
  );
}
