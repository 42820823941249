import {Link, createSearchParams} from "react-router-dom";
import "./ProjectCard.css";

export function ProjectCard({img, color, id, project}) {
  return (
    <Link
      to={`/projects/${id}`}
      state={{project, img, color}}
      className="content"
    >
      <img
        style={{backgroundColor: color}}
        src={img}
        alt={`project banner for ${project.title}`}
      />
      <div className="info">
        <h3>{project.title}</h3>
        <p>
          {project.location} - {project.year}
        </p>
      </div>
    </Link>
  );
}
