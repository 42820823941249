import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import "./ProjectDetailsPage.css";

export function ProjectsDetailsPage({}) {
  let {state} = useLocation();
  console.log(state.project);
  return (
    <div className="container">
      <div className="top">
        <h1>{state.project.title}</h1>
        <p>
          {state.project.location} - {state.project.year}
        </p>
      </div>
      <div className="middle">
        <img
          style={{backgroundColor: state.color}}
          src={state.img}
          alt="avatar drawing line"
        />
        <div>
          <p>{state.project.description}</p>
          {state.project.website && <p>{state.project.website}</p>}
        </div>
      </div>
      <div className="contact">
        <h3>Contact</h3>
        {state.project.contact && (
          <p>
            <img src="/telephone.svg" alt="telephone illustration" />{" "}
            {state.project.contact}
          </p>
        )}
        {state.project.email && (
          <p>
            <img src="/mail.svg" alt="mail illustration" />{" "}
            {state.project.email}
          </p>
        )}
      </div>
    </div>
  );
}
