import React from "react";
import PropTypes from "prop-types";

import "./button.css";
export function AppButton({theme, children, onClick, type}) {
  return (
    <button type={type} onClick={onClick} className={theme}>
      {children}
    </button>
  );
}

AppButton.propTypes = {
  theme: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};
