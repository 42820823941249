import "./RoleCard.css";
export function RoleCard({role, onClick, clicked}) {
  return (
    <button className="card" onClick={() => onClick()}>
      <img alt={role} src={`/${role.toLowerCase()}.svg`} />
      <h3>{role}</h3>
      <p>Click here</p>
    </button>
  );
}
