import React from "react";
import "./FlipCard.css";

export function StepCard({step, title}) {
  console.log(step);
  return (
    <li className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img
            src={step.img}
            alt={step.img}
            style={{width: "80%", height: "100%", margin: "auto"}}
          />
        </div>
        <div className="flip-card-back">
          <h2>{title}</h2>
          <p>{step.text}</p>
        </div>
      </div>
    </li>
  );
}
