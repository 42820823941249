import React from "react";
import "./contact.css";
import InputField from "../atoms/InputField";

export function ContactPage() {
  return (
    <div className="wrapper">
      <h1 className="contactHeading">Contact Us</h1>
      <div className="container">
        <form
          id="contact-form"
          onSubmit={() => console.log("clicked")}
          method="POST"
          className="left-half"
        >
          <InputField label="Your Name" type="text" placeholder="John Doe" />
          <InputField label="Subject" type="text" placeholder="Application" />
          <div className="form-group">
            <InputField
              label="Phone Number"
              type="text"
              placeholder="+xx xxx xx xx xx"
            />
          </div>
          <InputField
            label="Email"
            type="email"
            placeholder="john.doe@example.com"
          />
          <div className="form-group">
            <textarea
              className="form-control"
              rows="5"
              placeholder="Write your message here..."
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            Send
          </button>
        </form>
        <img src="/contactAnimation.gif" alt="GIF" className="gif" />
      </div>
    </div>
  );
}
