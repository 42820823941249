import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import "./AppLink.css";
export function AppLink({to, label, theme = "primary"}) {
  return (
    <a className={theme} href={to}>
      {label}
    </a>
  );
}

AppLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  theme: PropTypes.string, // Assuming theme is optional
};
