import React, {useState} from "react";
import PropTypes from "prop-types";
import "./HorizontalAccordion.css";

export function HorizontalAccordion({items}) {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (id) => {
    setActiveItem(id === activeItem ? null : id);
  };

  return (
    <div className="horizontal-accordion">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`accordion-item ${activeItem === item.id ? "active" : ""}`}
          style={{
            backgroundColor: `rgba(152, 209, 213, ${index * 0.25 + 0.25})`,
          }}
          onClick={() => handleItemClick(item.id)}
        >
          <div className="accordion-circle">{item.id}</div>
          <div className="accordion-content">
            <img src={`/avatars/${index + 1}.svg`} alt="avatar" />
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

HorizontalAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default HorizontalAccordion;
