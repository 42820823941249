import React, {useState} from "react";
import InputField from "../atoms/InputField";
import {AppLink} from "../atoms/AppLink";
import "./LoginPage.css";
import {AppButton} from "../atoms/AppButton";

export function LoginPage({}) {
  const [pwd, setPWD] = useState("");
  const [email, setEmail] = useState("");
  const [remember, setRemember] = useState(false);
  const [errors, setErrors] = useState([]);

  async function submit() {}
  return (
    <div>
      <div className="container">
        <section title="form">
          <h1>Log in</h1>
          <p className="gray">
            Welcome back! Please log in if you already have an account...
          </p>
          <ul className="errors">
            {errors.map((error) => (
              <li>
                <p>{error}</p>
              </li>
            ))}
          </ul>
          <form id="register-form" onSubmit={() => submit()}>
            <InputField
              theme="primary"
              label="Email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <InputField
              theme="primary"
              label="Password"
              type="password"
              placeholder="Password"
              value={pwd}
              onChange={(event) => setPWD(event.target.value)}
            />
            <InputField
              type="checkbox"
              theme="primary"
              label="Keep me logged in"
              value={remember}
              onChange={(event) => setRemember(event.target.value)}
            />
            <AppButton theme="secondary" type="submit">
              Log in
            </AppButton>
          </form>
          <p className="center">
            Need an account?{" "}
            <AppLink theme="secondary" to="/selectrole" label="Create one" />
          </p>
        </section>
        <section title="illustration">
          <img src="/bannerleft.svg" alt="big illustration left side" />
        </section>
      </div>
    </div>
  );
}
