import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {AppHeader} from "./components/molecules/AppHeader";
import {HomePage} from "./components/pages/HomePage";
import {ProjectsPage} from "./components/pages/ProjectsPage";
import {FAQPage} from "./components/pages/FAQPage";
import {ContactPage} from "./components/pages/ContactPage";
import {LoginPage} from "./components/pages/LoginPage";
import {AfterLoginPage} from "./components/pages/AfterLoginPage";
import {ProjectsDetailsPage} from "./components/pages/ProjectDetailsPage";
import {StakeholderPage} from "./components/pages/StakeholderPage";
import {StudentStakeholderPage} from "./components/pages/StudentStakeholderPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AppHeader />
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/projects/:id" element={<ProjectsDetailsPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/stakeholders/company"
        element={<StakeholderPage profile="Company" />}
      />
      <Route
        path="/stakeholders/student"
        element={<StudentStakeholderPage />}
      />
      <Route
        path="/stakeholders/university"
        element={<StakeholderPage profile="University" />}
      />
      <Route path="/secured" element={<AfterLoginPage />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
