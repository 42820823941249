import React from "react";

import "./AppLogo.css";
export function AppLogo() {
  return (
    <div className="logo">
      <img src="/logo.svg" alt="logo of BlendED" />
      <p>BlendED</p>
    </div>
  );
}
