import React from "react";
import PropTypes from "prop-types";
import {HorizontalAccordion} from "../atoms/HorizontalAccordion";
import "./StakeholderPage.css";

export function StakeholderPage({profile}) {
  let advantagesItems = [];

  if (profile === "Company") {
    advantagesItems = [
      {
        id: 1,
        content:
          "Tap into a diverse pool of qualified talent with relevant skills and knowledge.",
      },
      {
        id: 2,
        content:
          "Gain access to innovative perspectives, and creative solutions from students collaborating on real projects.",
      },
      {
        id: 3,
        content:
          "Enhances the company's employer brand and nurture young talent.",
      },
      {
        id: 4,
        content: "Ultimately contributes to the company’s growth and success.",
      },
    ];
  } else if (profile === "University") {
    advantagesItems = [
      {
        id: 1,
        content:
          "Enriches students' academic journey with practical, real-world learning experiences",
      },
      {
        id: 2,
        content:
          "Demonstrates dedication to fostering a dynamic learning environment via BlendED opportunities.",
      },
      {
        id: 3,
        content:
          "Enhances university curriculum and prepares students for professional demands.",
      },
      {
        id: 4,
        content:
          "Facilitates networking expansion with businesses and other academic institutions.",
      },
    ];
  }

  return (
    <div className="stakeholder-page">
      <div className="stakeholder-content">
        <section title="welcome">
          <div>
            <h1>{profile}</h1>
            {profile === "Company" && (
              <p>
                Since the start of the BlendED program, 23 companies have worked
                together with BlendED.
              </p>
            )}
            {profile === "University" && (
              <p>
                The BlendED programs works togheter with 17 European
                universities and 6 African universities.
              </p>
            )}
          </div>
          <img src="/companyAnimation.svg" alt="Company illustration" />
        </section>
        <section className="advantages">
          <h3>Advantages</h3>
          <HorizontalAccordion items={advantagesItems} />
        </section>
      </div>
    </div>
  );
}

StakeholderPage.propTypes = {
  profile: PropTypes.string.isRequired,
};

export default StakeholderPage;
