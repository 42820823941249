import React from "react";

import {AppLink} from "../atoms/AppLink";
import {AppLogo} from "../atoms/AppLogo";
import "./AppHeader.css";
import {AppButton} from "../atoms/AppButton";

export function AppHeader() {
  return (
    <header>
      <AppLogo />
      <nav>
        <ul>
          <li>
            <AppLink label="Home" to="/" />
          </li>
          <li>
            <AppLink label="Projects" to="/projects" />
          </li>
          <li>
            <AppLink label="FAQ" to="/faq" />
          </li>
          <li>
            <AppLink label="Contact us" to="/contact" />
          </li>
          <li>
            <AppLink label="Log in" to="https://forms.gle/iYei95iuyxxnVSKcA" />
          </li>
        </ul>
      </nav>
    </header>
  );
}
