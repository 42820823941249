import React from "react";
import "./faq.css";
import {AppHeader} from "../molecules/AppHeader";
import Accordion from "../atoms/Accordion";

export function FAQPage() {
  return (
    <div>
      <div className="faq-wrapper">
        <div className="gif-container">
          <img src="/faqAnimation.gif" alt="GIF" className="gif" />
        </div>
        <div className="faq-column--one">
          <h2 className="faq-heading">FAQ's</h2>
          <h3 className="faq-subheading" style={{marginLeft: "4rem"}}>
            Project overview
          </h3>
          <div className="faq-container">
            <div className="faq-list">
              <Accordion
                question="What kind of project can I expect?"
                answer="A Blended Mobility Project involves a multidisciplinary team (design, development, business, etc.) working on a project for a real-world client. You'll collaborate remotely with an in-person kickoff meeting and final presentation."
              />
              <Accordion
                question="What is the Blended Mobility Project exactly?"
                answer="A BMP is an international student project focused on teamwork and collaboration. It blends remote work with short in-person meetings for kick-off and project completion."
              />
              <Accordion
                question="How long does the BMP last?"
                answer="Typically a semester (4 months), but start/end dates may differ from your regular program. Time commitment depends on your ECTS."
              />
            </div>

            <div className="faq-list">
              <h4 className="faq-subheading">
                Project Logistics and Commitment
              </h4>

              <Accordion
                question="How much time will I spend on the BMP?"
                answer="Time commitment depends on your ECTS credits. Expect involvement throughout the whole project, regardless of the workload."
              />
              <Accordion
                question="Is the BMP part of my normal study program?"
                answer="There are three options:

                - BMP replaces courses in your program (you get ECTS for those).

                - BMP is in addition to your program (ECTS may or may not be granted).

                - Your institution may have specific rules."
              />
              <Accordion
                question="How many ECTS credits will I get?"
                answer="Credits vary, with a minimum of 6 being typical. Consult your teacher for specifics."
              />
              <Accordion
                question="Will I get exemptions for other courses?"
                answer="Potentially, if the BMP replaces courses in your program. Discuss this with your teacher."
              />
              <Accordion
                question="How long do I have to be abroad?"
                answer="There are two physical meetings in the BMP: one for the kick-off of the project and one at the end for the final presentation. Each time you will have five full working days to meet your team and work on your project. You should consider the time you need to travel to the meetings as well. Most probably you will travel on Sunday and return on Saturday."
              />
            </div>

            <div className="faq-list">
              <h4 className="faq-subheading">
                Skill Development and Expectations
              </h4>
              <Accordion
                question="How will the BMP improve my social skills?"
                answer="Through international collaboration, virtual teamwork, and utilizing collaborative tools."
              />
              <Accordion
                question="How do I improve teamwork with the BMP?"
                answer="Take time to know your teammates, use collaborative tools effectively, and focus on communication."
              />
              <Accordion
                question="Will the BMP meet my expectations?"
                answer="BMPs aim to develop both technical and soft skills. Success depends on your level of participation and interaction within the international setting."
              />
            </div>

            <div className="faq-list">
              <h4 className="faq-subheading">Travel and Accommodation</h4>
              <Accordion
                question="What kind of travel experiences can I expect?"
                answer="You'll travel abroad for the kick-off and final meetings, each around a week long."
              />
              <Accordion
                question="How much will the BMP cost?"
                answer="Costs mainly involve travel and accommodation for the two meetings, plus personal expenses. Your institution may reimburse some or all of these costs – talk to your teacher."
              />
              <Accordion
                question="Do I have to pay travelling myself?"
                answer="Usually, you don't have to pay for traveling and accommodation. Depending on the arrangements in the BMP, you might need to pay for your food and drinks on your own. There might be some official events where you can get a reimbursement for your expenses. You should talk to your teacher about this so that you exactly know what the arrangement is."
              />
              <Accordion
                question="Who arranges the travelling?"
                answer="The organizing committee fixes the dates and the places for the kick-off meeting and the final presentation. The responsible teacher at your institution will provide you with the details. It depends on your institution if you have to book the travel and accommodation yourself or if your teacher will do it. Nevertheless, your teacher will assist you and can answer important questions about travel and accommodation."
              />
              <Accordion
                question="Is there free time during time abroad?"
                answer="This depends on the meeting schedule and the project status. Usually, there are time slots where you as a team can decide whether you want to keep working or do something else. Also check with your local institution whether it's okay to stay during the weekend before or after the project meeting. That gives you some extra time to visit the area. Of course, you'll have to pay yourself for these extra days, regardless of whether your institution refunds your travel or not."
              />
              <Accordion
                question="Do I need a visa?"
                answer="Within Europe, there is no need for a visa, but if it concerns an international cooperation with non-European institutions, it may be necessary to get a visa. Your teacher will provide you this information beforehand so that you have enough time to arrange your visa."
              />
            </div>

            <div className="faq-list">
              <h4 className="faq-subheading">
                Evaluation and Conflict Resolution
              </h4>
              <Accordion
                question="How is the BMP evaluated?"
                answer="Evaluation varies by project but typically includes factors like teamwork, peer assessment, individual contributions, documentation, reports, and presentations."
              />
              <Accordion
                question="What if I disagree with a decision?"
                answer="Communication is a very important aspect when working in a team. The team will have regular meetings where topics are discussed and decisions are made. Students within the team will be given certain responsibilities. However, should the team not be able to solve the problem itself, the teacher board should be contacted in order to take appropriate actions."
              />
            </div>

            <div className="faq-list">
              <h4 className="faq-subheading">Application Process</h4>
              <Accordion
                question="How do I write a good motivation letter?"
                answer="Always write with the people who will read your letter in mind. What do they want to know about you? Why would they choose you? Introduce your name, age, study programme and year in one short sentence. It's important to make sure they see you as someone with good social skills: pro-active, good team spirit, positive collaborative mindset."
              />
              <Accordion
                question="How do I do a good pitch video to sell myself?"
                answer="Same rules as above apply! Make sure the audio has good quality so everything you say can be understood. Keep it simple and be your best self. Use a tripod to avoid shaky video."
              />
            </div>

            <div className="contactLink">
              <h3 className="contactLinkHeading">Still have any Questions?</h3>
              <a href="/contact">Contact us!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
