import React from "react";
import "./inputField.css";

const InputField = ({
  theme = "primary",
  label,
  type,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className={type === "checkbox" ? "checkbox inputgroup" : "inputgroup"}>
      <label>{label}</label>
      <input
        id={label}
        type={type}
        className="form-control"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputField;
