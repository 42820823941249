import React, {useState} from "react";
// import "@fortawesome/fontawesome-free/css/all.css";
import "./accordion.css";

function Accordion({question, answer}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? "active" : ""}`}>
      <div className="accordionHeader" onClick={toggleAccordion}>
        <div className="accordionQuestion">
          <h4 className="accordionHeading">{question}</h4>
        </div>
        <div className="accordionToggleContainer">
          <i
            className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
      </div>
      <div
        className="accordionTextContainer"
        style={{display: isOpen ? "block" : "none"}}
      >
        <p className="accordionText">{answer}</p>
      </div>
    </div>
  );
}

export default Accordion;
